import { Input } from 'antd';
import type { PaginationData } from 'egenie-utils';
import { request, ImgFormatter, MainSubStructureModel } from 'egenie-utils';
import React from 'react';
import { logSubGrid } from '../../utils';
import styles from './index.less';
import type { IReveiveDataList } from './interface';
import { requestCenter } from './request';
import { programme } from './index';

/**
 *
 * 操作列
 * @param {*} { row }
 * @returns
 */
function ActionColumn({ row }) {
  /*   0 未确认
     1 已确认未收货
     2 拒绝收货
     3 部分收货
     4 全部收货
     5 部分入库
     6 已入库 */
  const {
    wmsReceiveOrderId,
    wmsReceiveOrderStatus,
    closedType,
  } = row;
  const wros = wmsReceiveOrderStatus;// 定义别名取驼峰的首字母
  return (
    <div className={styles.rowBtnGroup}>
      {
        (wros === 0 || wros === 2) && closedType === 0 && (
          <>
            <span
              className={styles.egenieRowAction}
              onClick={requestCenter.agreeOrRefuseReveive.bind(this, true, wmsReceiveOrderId)}
            >
              同意送货
            </span>
            <span
              className={styles.rowBtnSplit}
            >
              |
            </span>
            <span
              className={styles.egenieRowAction}
              onClick={requestCenter.agreeOrRefuseReveive.bind(this, false, wmsReceiveOrderId)}
            >
              拒绝送货
            </span>
          </>
        )
      }
      {
        (wros === 1 || wros === 3 || wros === 5) && closedType === 0 && (
          <span
            className={styles.egenieRowAction}
            onClick={requestCenter.confirmReceive.bind(this, row)}
          >
            确认收货
          </span>
        )
      }
      {
        ((wros === 3 || wros === 5) && closedType === 0) && (
          <span
            className={styles.rowBtnSplit}
          >
            |
          </span>
        )
      }
      {
        (wros === 3 || wros === 4 || wros === 5) && closedType === 0 && (
          <span
            className={styles.egenieRowAction}
            onClick={requestCenter.generateInStock.bind(this, row)}
          >
            生成入库单
          </span>
        )
      }

    </div>
  );
}

function StatusCell({
  status,
  colorType,
  dict,
}) {
  return (
    <div className={styles.statusPointWrap}>
      <div className={colorType}/>
      {dict ? (dict.find((v) => Number(v.value) === Number(status))?.label || '') : ''}
    </div>
  );
}

function RightHeader({ name }) {
  return (
    <div className={styles.rowlr}>
      {name}
    </div>
  );
}

export const receiptTableModel = new MainSubStructureModel({
  pageId: '1408',

  /*
  buttons: [
    {
      permissionId: '2036',
      text: '同意送货',
      handleClick: () => {
        console.log('同意送货');
        requestCenter.agreeOrRefuseReveive(true);
      },
      icon: 'icon-btn_xj',
      display: (rows) => rows.every((el) => {
        return true;
      }),
    },
    {
      text: '拒绝送货',
      permissionId: '2037',
      icon: 'icon-refuse',
      handleClick: () => {
        requestCenter.agreeOrRefuseReveive(false);
      },
    },
    {
      text: '打印收货单',
      permissionId: '2038',
      icon: 'icon-d_print',
      handleClick: async() => {
        if (programme.gridModel.gridModel.selectedIds.size !== 1) {
          message.error({
            key: '请勾选一条数据',
            content: '请勾选一条数据',
          });
          return;
        }

        const {
          templateId,
          tempType,
          printer,
          preview,
        } = await getCustomPrintParam('21');
        const { gridModel: { selectedIds }} = programme.gridModel;
        const printData = await request<BaseData<{ userDataList: any; tempData: any; }>>({
          url: '/api/cloud/print/wms/receive_order/query/receive/order/print/data',
          method: 'POST',
          data: {
            tempId: templateId,
            tempType,
            wmsReceiveOrderId: Array.from(selectedIds)
              .join(','),
          },
        });

        printHelper.toggleToLodop();
        await printHelper.print({
          preview,
          printer,
          templateData: printData?.data?.tempData,
          contents: printData?.data?.userDataList,
        });
      },
      group: [
        {
          permissionId: '2038',
          text: '打印唯一码',
          icon: 'icon-d_print',
          handleClick: async() => {
            if (programme.gridModel.gridModel.selectedIds.size !== 1) {
              message.error('请勾选一条数据');
              return;
            }

            const {
              templateId,
              tempType,
              printer,
              preview,
            } = await getCustomPrintParam('27');
            const { gridModel: { selectedIds }} = programme.gridModel;
            const printData = await request<BaseData<{ userDataList: any; tempData: any; }>>({
              url: '/api/cloud/print/wms/receive_order/get/unique/code/print/data',
              method: 'POST',
              data: {
                tempId: templateId,
                tempType,
                wmsReceiveOrderId: Array.from(selectedIds)
                  .join(','),
              },
            });

            printHelper.toggleToLodop();
            await printHelper.print({
              preview,
              printer,
              templateData: printData?.data?.tempData,
              contents: printData?.data?.userDataList,
            });
          },
        },
      ],
    },
  ],
*/
  grid: {
    columns: [
      /*      {
        key: 'operate',
        name: '操作',
        width: 150,
        frozen: true,
        resizable: false,
        formatter: ({ row }) => {
          return <ActionColumn row={row}/>;
        },
      },*/
      {
        key: 'wmsReceiveOrderNo',
        name: '收货单编号',
        width: 200,
        sortable: true,
      },
      {
        key: 'tag',
        name: '标记',
      },
      {
        key: 'createStockInType',
        name: '生成入库单',
        width: 100,
        formatter: ({ row }) => {
          const status = row.createStockInType;
          const { createStockInType } = programme.filterItems.dict;
          return (
            <StatusCell
              colorType={status === 0 ? styles.greyPoint : status === 1 ? styles.bluePoint : ''}
              dict={createStockInType}
              status={row.createStockInType}
            />
          );
        },
      },
      {
        key: 'totalNumber',
        name: <RightHeader name="总数量"/>,
        sortable: true,
        formatter: ({ row }) => {
          return (
            <div className={styles.rowlr}>
              {row.totalNumber}
            </div>
          );
        },
      },
      {
        key: 'closedType',
        name: '关闭状态',
        width: 100,
        formatter: ({ row }) => {
          const status = row.closedType;
          const { closedType } = programme.filterItems.dict;
          return (
            <StatusCell
              colorType={status === 0 ? styles.greyPoint : status === 1 ? styles.redPoint : ''}
              dict={closedType}
              status={row.closedType}
            />
          );
        },
      },
      {
        key: 'wmsReceiveOrderStatus',
        name: '单据状态',
        width: 150,
        formatter: ({ row }) => {
          const {
            progressValue,
            color,
            value,
          } = getProgressValue(row.wmsReceiveOrderStatus);
          return (
            <div className={styles.rowProgressWrap}>
              <div className={styles.progressTop}>
                {value}
              </div>
              <progress
                className={`${styles.progress} ${color}`}
                max={100}
                style={{ width: '70%' }}
                value={progressValue}
              />
            </div>
          );
        },
      },
      {
        key: 'vendorName',
        name: '供应商',
        width: 150,
        sortable: true,
      },
      {
        key: 'warehouseName',
        name: '仓库',
        width: 150,
        sortable: true,
        editable: true,
      },
      {
        key: 'sourceNo',
        name: '来源单号',
        width: 150,
        sortable: true,
      },
      {
        key: 'wmsReceiveSourceTypeName',
        name: '收货单来源',
        width: 150,
      },
      {
        key: 'operatorName',
        name: '操作人',
        width: 150,
      },
      {
        key: 'createTime',
        name: '创建时间',
        width: 200,
      },
      {
        key: 'confirmReceiveTime',
        name: '确认收货时间',
        width: 200,
        sortable: true,
      },
      {
        key: 'remark',
        name: '备注',
        width: 150,
        editable: true,

        // editor: TestInput,

        // editorOptions: { editOnClick: false },

        formatter: ({ row }) => {
          const { wmsReceiveOrderId } = row;
          return (
            <Input
              className={styles.remark}
              defaultValue={row.remark}
              maxLength={25}
              onBlur={requestCenter.onBlurMark.bind(this, wmsReceiveOrderId)}
              onClick={requestCenter.onClickMark}
            />
          );
        },
      },
      {
        key: 'ownerName',
        name: '货主',
        width: 200,
      },
    ].map((v) => ({
      resizable: true,
      sortable: false,
      ...v,
    })),
    rows: [],
    primaryKeyField: 'wmsReceiveOrderId',
    sortByLocal: false,
    showCheckBox: true,
    showEmpty: true,
    gridIdForColumnConfig: 'wmsReceiveOrderMainTable',
    setColumnsDisplay: true,
  },

  // 主表查询api
  api: {
    onQuery: (params) => {
      console.log(params, 'paramsparams');
      const {
        filterParams,
        ...rest
      } = params;
      return request<PaginationData<IReveiveDataList>>({
        url: '/api/cloud/wms/rest/receiveOrder/listByPage',
        method: 'POST',
        data: {
          ...filterParams,
          ...rest,
        },

        // data: `vo=${JSON.stringify({ dateType: 'sale_order_status.pay_time' })}&page=${page}&pageSize=${pageSize}&sord=${sord}&sidx=${sidx}`,
      });
    },
  },
  subTables: {
    activeTab: 'detail',
    tabsFlag: {
      inited: {
        detail: true,
        log: false,
      },
      searched: {},
    },
    list: [
      {
        tab: {
          name: '收货单明细',
          value: 'detail',
        },
        grid: {
          columns: [
            {
              key: 'tag',
              name: '标记',
            },
            {
              key: 'picUrl',
              name: '图片',
              formatter: ({ row }) => {
                return <ImgFormatter value={row.picUrl}/>;
              },
            },
            {
              key: 'productNo',
              name: '商品编码',
              width: 250,
            },
            {
              key: 'skuNo',
              name: 'SKU编码',
              width: 250,
            },
            {
              key: 'colorType',
              name: '颜色',
            },
            {
              key: 'sizeType',
              name: '尺码',
            },
            {
              key: 'receivePlanNum',
              name: <RightHeader name="计划收货数量"/>,
              width: 120,
              formatter: ({ row }) => {
                return (
                  <div className={styles.rowlr}>
                    {row.receivePlanNum}
                  </div>
                );
              },
            },
            {
              key: 'totalReceiveRealNum',
              name: <RightHeader name="累计收货数量（正品）"/>,

              /* name: (
                   <CustomHeader
                     children={<i className="icon-sign_wai"/>}
                     title="累计收货数量（正品）"
                   />
                 ), */
              width: 180,
              formatter: ({ row }) => {
                const {
                  totalReceiveRealNum,
                  totalReceiveDefectiveNum,
                  receivePlanNum,
                } = row;
                const total = totalReceiveRealNum + totalReceiveDefectiveNum;
                return (
                  <div className={`${styles.rowlr} ${total > receivePlanNum ? styles.clr : total < receivePlanNum ? styles.cly : ''}`}>
                    {totalReceiveRealNum}
                  </div>
                );
              },
            },
            {
              key: 'totalReceiveDefectiveNum',
              name: <RightHeader name="累计收货数量（次品）"/>,

              /* name: (
                   <CustomHeader
                     children={<i className="icon-sign_wai"/>}
                     title="累计收货数量（次品）"
                   />
                 ), */
              width: 180,
              formatter: ({ row }) => {
                const {
                  totalReceiveRealNum,
                  totalReceiveDefectiveNum,
                  receivePlanNum,
                } = row;
                const total = totalReceiveRealNum + totalReceiveDefectiveNum;
                return (
                  <div className={`${styles.rowlr} ${total > receivePlanNum ? styles.clr : total < receivePlanNum ? styles.cly : ''}`}>
                    {totalReceiveDefectiveNum}
                  </div>
                );
              },
            },
            {
              key: 'planSendTime',
              name: '预计送货日期',
              width: 120,
            },
            {
              key: 'totalStockInRealNum',
              name: <RightHeader name="累计入库数量（正品）"/>,
              width: 160,
              formatter: ({ row }) => {
                return (
                  <div className={styles.rowlr}>
                    {row.totalStockInRealNum}
                  </div>
                );
              },
            },
            {
              key: 'totalStockInDefectiveNum',
              name: <RightHeader name="累计入库数量（次品）"/>,
              width: 160,
              formatter: ({ row }) => {
                return (
                  <div className={styles.rowlr}>
                    {row.totalStockInDefectiveNum}
                  </div>
                );
              },
            },
          ].map((v) => ({
            resizable: true,
            sortable: false,
            ...v,
          })),
          rows: [],
          primaryKeyField: 'wmsReceiveOrderDetailId',
          sortByLocal: false,
        },
        api: {
          onQuery: ({
            data,
            pid,
            cursorRow,
            gridModel,
          }) => {
            const {
              sidx,
              sord,
              page,
              pageSize,
            } = data;
            return request({
              url: '/api/cloud/wms/rest/receiveOrder/pageQueryDetail',
              method: 'POST',
              data: {
                sidx,
                sord,
                page,
                pageSize,
                wmsReceiveOrderId: pid,
              },
            });
          },
        },
      },
      logSubGrid(20000),

      // {
      //   tab: {
      //     name: 'custom',
      //     value: '自定义',
      //   },
      //   isCustom: true, // 是否自定义子表
      //   customModel: test, // 自定义model
      //   customView: observer(({ store }) => { // 自定义view
      //     return (
      //       <div>
      //         <Button onClick={store.handleClick}>
      //           点击更改store
      //         </Button>
      //         {store.test || 123}
      //       </div>
      //     );
      //   }),
      //   api: {
      //     // 得到主表rowid及主表cursorRow
      //     onQuery({ data, pid, cursorRow, gridModel }) {
      //       console.log({
      //         data,
      //         pid,
      //         cursorRow,
      //         gridModel,
      //       });

      //       return Promise.resolve(true);
      //     },
      //   },
      // },
    ],
  },
});

const getProgressValue = function(status): { progressValue: number; color: React.CSSProperties; value: string; } {
  /*   0 未确认
     1 已确认未收货
     2 拒绝收货
     3 部分收货
     4 全部收货
     5 部分入库
     6 已入库 */
  switch (status) {
    case 0: {
      return {
        progressValue: 100,
        color: styles.bgr,
        value: '未确认',
      };
    }
    case 1: {
      return {
        progressValue: 20,
        color: styles.bgb,
        value: '已确认未收货',
      };
    }
    case 2: {
      return {
        progressValue: 0,
        color: styles.bgb,
        value: '拒绝收货',
      };
    }
    case 3: {
      return {
        progressValue: 50,
        color: styles.bgb,
        value: '部分收货',
      };
    }
    case 4: {
      return {
        progressValue: 100,
        color: styles.bgb,
        value: '全部收货',
      };
    }
    case 5: {
      return {
        progressValue: 50,
        color: styles.bgg,
        value: '部分入库',
      };
    }
    case 6: {
      return {
        progressValue: 100,
        color: styles.bgg,
        value: '已入库',
      };
    }
    default: {
      return {
        progressValue: 0,
        color: styles.bgg,
        value: '',
      };
    }
  }
};

