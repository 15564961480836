import { message } from 'antd';
import { renderModal } from 'egenie-common';
import type { BaseData, BatchReportData } from 'egenie-utils';
import { BatchReport, request } from 'egenie-utils';
import React from 'react';
import type { IReveiveDataList } from './interface';
import { programme } from './index';

export const requestCenter = {
  async agreeOrRefuseReveive(agree?: boolean, wmsReceiveOrderIds?: number) {
    let ids: Array<string | number>;
    const { gridModel: { selectedIds, onRefresh }} = programme.gridModel;
    if (wmsReceiveOrderIds) {
      ids = [wmsReceiveOrderIds];
    } else {
      if (!selectedIds.size) {
        return message.error('请选择数据');
      }
      ids = Array.from(selectedIds);
    }

    const res: BatchReportData = await request<BatchReportData<{ wmsReceiveOrderIds: number[]; orderStatusParams: number; }>>({
      url: '/api/cloud/wms/rest/receiveOrder/acceptOrRefuse/receive',
      method: 'POST',
      data: {
        wmsReceiveOrderIds: ids,
        orderStatusParams: agree ? 1 : 2,
      },
    });
    renderModal(
      <BatchReport
        {...res.data}
        columns={[
          {
            title: '收货单编号',
            dataIndex: 'serialNo',
          },
          {
            title: '失败原因',
            dataIndex: 'reason',
          },
        ]}
      />
    );
    onRefresh();
  },
  confirmReceive(row?: IReveiveDataList) {
    const { wmsReceiveOrderId } = row;
    const ownerName = programme.filterItems.dict.ownerId?.find((v) => Number(v.value) === Number(row.ownerId))?.label ?? '';
  },
  generateInStock(row?: IReveiveDataList) {
    const { wmsReceiveOrderId, warehouseId, warehouseName, wmsReceiveOrderNo, ownerId } = row;
    const ownerName = programme.filterItems.dict.ownerId?.find((v) => Number(v.value) === Number(row.ownerId))?.label ?? '';
  },
  onBlurMark(wmsReceiveOrderId, event) {
    console.log(event.target.value);
    request<BaseData<{ wmsReceiveOrderId: number; remark: string; }>>({
      url: '/api/cloud/wms/rest/receiveOrder/editRemark',
      method: 'POST',
      data: {
        wmsReceiveOrderId,
        remark: event.target.value,
      },
    }).then((v) => {
      message.success(v.data || v.info);
    });
  },
  onClickMark(event) {
    event.stopPropagation();
  },
};

