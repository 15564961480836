import type { FilterConfigData } from 'egenie-utils';
import { formatFilterConfigData, Programme, ProgrammeComponent, request } from 'egenie-utils';
import React from 'react';
import { receiptTableModel } from './receiptTableModel';
import './index.less';

export const programme = new Programme({
  gridModel: receiptTableModel,
  filterItems: [
    {
      type: 'date',
      field: 'date',
      label: '日期类型',
      selectValue: 'createTime',
      data: [
        {
          value: 'createTime',
          label: '创建时间',
        },
        {
          value: 'confirmReceiveTime',
          label: '确认收货时间',
        },
      ],
    },
    {
      type: 'inputAndSelect',
      label: '单号',
      field: 'wmsReceiveSourceType',
      selectValue: 'wmsReceiveOrderNo',
      placeholder: '来源单号/收货单编号',
      data: [
        {
          value: 'sourceNo',
          label: '来源单号',
        },
        {
          value: 'wmsReceiveOrderNo',
          label: '收货单编号',
        },
      ],
    },
    {
      type: 'radio',
      label: '生成入库单',
      field: 'createStockInType',
      data: [],
    },
    {
      type: 'radio',
      label: '关闭状态',
      field: 'closedType',
      data: [],
    },
    {
      type: 'checkbox',
      label: '单据状态',
      field: 'wmsReceiveOrderStatus',
      data: [],
    },
    {
      type: 'select',
      mode: 'multiple',
      label: '供应商',
      field: 'ownerId',
    },
    {
      type: 'input',
      label: '备注',
      field: 'remark',
    },
    {
      type: 'select',
      mode: 'multiple',
      label: '仓库',
      field: 'warehouseId',
      data: [],
    },
  ],
  moduleName: 'WMSReceiveOrders',
});

export default function() {
  React.useEffect(() => {
    request<FilterConfigData>({
      url: '/api/cloud/baseinfo/rest/filterSet2/getConfig2',
      params: {
        itemList: 'warehouse,owner,user',
        dictList: 'wms_receive_order_status,closed_type,create_stock_in_type,date_type,wms_receive_order_source',
        module: '',
      },
    })
      .then((info) => {
        const list = formatFilterConfigData(info, {
          create_stock_in_type: 'createStockInType',
          closed_type: 'closedType',
          wms_receive_order_status: 'wmsReceiveOrderStatus',
          warehouse: 'warehouseId',
          owner: 'ownerId',
          wms_receive_order_source: 'wmsReceiveOrderSource',
        });
        programme.filterItems.addDict(list.reduce((prev, current) => ({
          ...prev,
          [current.field]: current.data,
        }), {}));
      });
  }, []);
  return (
    <ProgrammeComponent store={programme}/>
  );
}
